<style lang="scss">
.startseite {
  .img-intro-box {
    //border:1px solid red;

    top: -45px;
    position: relative;

    .box-outer {
      margin: 2px;
    }
    .d-none-lg {
      @media (max-width: $width-lg) {
        display: none;
      }
    }
    .d-none-md {
      @media (max-width: $width-md) {
        display: none;
      }
    }
    .d-none-sm {
      @media (max-width: $width-sm) {
        display: none;
      }
    }
    .border-outer-txt {
      //border-right:1px solid $blue;
      //border-bottom:1px solid $blue;
      //background:$blue-darker;
    }
    .box-inner {
      width: 100%;
      height: 250px;

      background-size: cover;
      background-position: center center;
      position: relative;

      @media (max-width: $width-sm) {
        height: 200px;
      }
      .img-logo {
        max-width: 40%;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        img {
        }
      }
      .txt-box {
        text-align: center;
        padding: 15px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        h1 {
          font-size: 200%;
          font-weight: 300;
          //color:#fff;
        }
        span {
          display: block;
          color: $red;
          font-size: 150%;
          font-family: $KleineSans-Bold;
        }
      }
    }
  }
  $animation-seconds: 2.5s;
  $animation-seconds-none: 0s;
  .scale-in {
    animation-name: scale-in;
    animation-duration: $animation-seconds;
    animation-fill-mode: forwards;
  }

  .scale-in-logo {
    animation-name: scale-in-logo;
    animation-duration: $animation-seconds;
    animation-fill-mode: forwards;
  }

  @keyframes scale-in {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes scale-in-logo {
    0% {
      opacity: 0;
      transform: scale(0) translateY(-50%);
    }
    100% {
      opacity: 1;
      transform: scale(1) translateY(-50%);
    }
  }

  .animation-delay-0-5s {
    opacity: 0;
    animation-delay: 0.5s;
    @media (max-width: $width-sm) {
      animation-delay: $animation-seconds-none;
    }
  }
  .animation-delay-1s {
    opacity: 0;
    animation-delay: 1s;
    @media (max-width: $width-sm) {
      animation-delay: $animation-seconds-none;
    }
  }
  .animation-delay-1-5s {
    opacity: 0;
    animation-delay: 1.5s;
    @media (max-width: $width-sm) {
      animation-delay: $animation-seconds-none;
    }
  }
  .animation-delay-2s {
    opacity: 0;
    animation-delay: 2s;
    @media (max-width: $width-sm) {
      animation-delay: $animation-seconds-none;
    }
  }
  .animation-delay-2-5s {
    opacity: 0;
    animation-delay: 2.5s;
    @media (max-width: $width-sm) {
      animation-delay: $animation-seconds-none;
    }
  }
  .animation-delay-3s {
    opacity: 0;
    animation-delay: 3s;
    @media (max-width: $width-sm) {
      animation-delay: $animation-seconds-none;
    }
  }
  .animation-delay-3-5s {
    opacity: 0;
    animation-delay: 3.5s;
    @media (max-width: $width-sm) {
      animation-delay: $animation-seconds-none;
    }
  }
  .animation-delay-4s {
    opacity: 0;
    animation-delay: 4s;
    @media (max-width: $width-sm) {
      animation-delay: $animation-seconds-none;
    }
  }
  h1 {
    font-weight: 500;
  }

  h2 {
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  p {
    line-height: 1.8;
    margin: 10px 0;
  }

  .info-grafik {
    padding: 100px 0;
    .txt-box {
      padding: 0px 0px;
      //border:1px solid red;
      text-align: center;
      margin: 15px 0;

      @media (max-width: $width-md) {
        //border-bottom: 2px solid $red;
        border-left: 0;
        text-align: center !important;
      }
      .img-icon {
        margin-top: 30px;
        margin-bottom: 30px;
        display: inline-block;
        padding: 0 10px;
        height: 90px;
        img {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .img-std {
        img {
          width: 100px;
        }
      }
      .img-karte {
        img {
          width: 160px;
        }
      }
      .img-uhr {
        img {
          width: 70px;
        }
      }
      .nr {
        font-size: 240%;
        color: $blue-darker;
        line-height: 1;
        letter-spacing: 4px;
        font-family: $KleineTitelCondensed-Bold;
      }
      p {
        margin: 15px 0;
        @media (max-width: $width-md) {
          padding: 10px 10px 30px 10px;
        }
      }
    }
    .border-bottom-none {
      @media (max-width: $width-md) {
        border-bottom: 0;
      }
    }
    .border-line {
      width: 100%;
      height: 1px;
      background: $red;
      margin: 10px 0;
      display: none;
      @media (max-width: $width-md) {
        display: none;
      }
    }
    .border-right-line {
      //border-right: 1px solid $red;
      @media (max-width: $width-md) {
        border-right: 0;
      }
    }
    .border-right-line-lg {
      @media (max-width: $width-lg) {
        border-right: 0;
      }
    }
  }

  .category-img-box {
    margin-bottom: 150px;
    h2 {
      text-transform: none;
      //border:1px solid red;
      font-size: 180%;
      text-align: center;
      position: relative;
      height: 80px;
      text-align: center;
      border-left: 0;
      span {
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .img-box {
      margin: 15px 20px;
      background: #eee;
      overflow: hidden;
      border-bottom: 10px solid $blue-light;
      .img-box-inner {
        width: 100%;
        height: 450px;
        background-size: cover;
        position: relative;
        background-position: center center;
      }
      .overlay-bg {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        //background-color: rgba($color: $blue-light, $alpha: 0.8);
        background: rgb(2, 0, 36);
        background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(92, 109, 198, 1) 100%);
        transition: all 400ms ease;
        opacity: 0;
        @media (max-width: $width-lg) {
          opacity: 1;
        }
      }
      .txt {
        width: 100%;
        font-family: $KleineTitelCondensed-Bold;
        padding: 20px;
        position: relative;
        top: 250%;
        transition: all 400ms ease-in-out;
        text-align: center;
        color: #fff;
        font-size: 180%;
        @media (max-width: $width-lg) {
          top: 70%;
          transform: translateY(0%);
        }
      }
      a {
        display: block;
      }
      &:hover {
        .overlay-bg {
          opacity: 1;
        }
        .txt {
          top: 70%;
          transform: translateY(0%);
          opacity: 1 !important;
        }
      }
    }
  }
}
</style>

<template>
  <div class="startseite">
    <div class="wrapper-xxl img-intro-box">
      <div class="row no-gutters justify-content-center">
        <div class="col-lg-3 col-md-6 col-6 justify-content-center align-self-center">
          <div class="box-outer">
            <div class="box-inner scale-in" :style="{ 'background-image': `url(${require('@/assets/img/img-content/startseite/themenschwerpunkt-tourismus.jpg')})` }"></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-6 justify-content-center align-self-center">
          <div class="box-outer">
            <div class="box-inner scale-in animation-delay-1-5s" :style="{ 'background-image': `url(${require('@/assets/img/img-content/startseite/frau-kanu.jpg')})` }"></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-6 justify-content-center align-self-center">
          <div class="box-outer">
            <div class="box-inner scale-in scale-in animation-delay-2-5s" :style="{ 'background-image': `url(${require('@/assets/img/img-content/startseite/basis-sonntagsbeilage.jpg')})` }"></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-6 justify-content-center align-self-center">
          <div class="box-outer">
            <div class="box-inner scale-in scale-in animation-delay-0-5s" :style="{ 'background-image': `url(${require('@/assets/img/img-content/startseite/mann-fruehstueck.jpg')})` }"></div>
          </div>
        </div>
        <!-- -->
        <div class="col-lg-3 col-md-6 col-12 justify-content-center align-self-center d-none-lg">
          <div class="box-outer">
            <div class="box-inner scale-in scale-in animation-delay-1s" :style="{ 'background-image': `url(${require('@/assets/img/img-content/startseite/themenschwerpunkt-bildung.jpg')})` }"></div>
          </div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <div class="box-outer border-outer-txt">
            <div class="box-inner">
              <div class="txt-box">
                <h1>Wir verbinden Marken mit Menschen.</h1>
                <span>Täglich. National. Regional.</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-6 justify-content-center align-self-center">
          <div class="box-outer">
            <div class="box-inner scale-in animation-delay-1s" :style="{ 'background-image': `url(${require('@/assets/img/img-content/startseite/styria-hq.jpg')})` }"></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-6 justify-content-center align-self-center">
          <div class="box-outer">
            <div class="box-inner scale-in animation-delay-0-5s" :style="{ 'background-image': `url(${require('@/assets/img/img-content/startseite/frau-wohnen-fensterbank.jpg')})` }"></div>
          </div>
        </div>
        <!-- -->
        <div class="col-lg-3 col-md-6 col-6 justify-content-center align-self-center">
          <div class="box-outer">
            <div class="box-inner scale-in .animation-delay-1-5s" :style="{ 'background-image': `url(${require('@/assets/img/img-content/startseite/basis-sport.jpg')})` }"></div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-6 justify-content-center align-self-center">
          <div class="box-outer">
            <div class="box-inner scale-in animation-delay-1s" :style="{ 'background-image': `url(${require('@/assets/img/img-content/startseite/basis-besser-leben.jpg')})` }"></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 justify-content-center align-self-center d-none-lg">
          <div class="box-outer">
            <div class="box-inner">
              <div class="img-logo scale-in-logo animation-delay-0-5s"><img src="@/assets/img/generel/klz-logo.svg" alt="Kleine Zeitung" class="img-fluid logo-img" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="whitespace-arrow"><span class="material-icons">expand_more</span></div>

    <div class="container margin-tb-m">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-10 padding-tb-s">
          <h2>Kommunikation, die ankommt.</h2>
          <p>
            Bei uns geht es um Sie! Ganz nach Ihrem individuellen Bedarf entwickeln wir wirksame Kommunikationslösungen, mit denen Sie Ihre Zielgruppe optimal quer über alle Kanäle des Kleine-Zeitung-Netzwerks erreichen und begeistern. Dafür sorgen eine starke Marke, unser multimediales Produkt-Portfolio und unsere kreativen Werbeberaterinnen und Werbeberater, für die keine Herausforderung zu groß
            ist.
          </p>
        </div>
      </div>
    </div>

    <div class="container category-img-box">
      <div class="row justify-content-center no-gutters">
        <div class="col-lg-4">
          <h2><span>Digital</span></h2>
          <div class="img-box">
            <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/img-content/startseite/digital-img.jpg')})` }">
              <div class="overlay-bg"></div>
              <div class="txt">Mehr entdecken</div>
              <router-link :to="{ name: 'DisplayWerbung' }" class="stretched-link"></router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 ">
          <h2><span>Print</span></h2>
          <div class="img-box">
            <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/img-content/startseite/print-img.jpg')})` }">
              <div class="overlay-bg"></div>
              <div class="txt">Mehr entdecken</div>
              <router-link :to="{ name: 'Platzierungen' }" class="stretched-link"></router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 ">
          <h2><span>Branchenlösungen &amp; Sonderthemen</span></h2>
          <div class="img-box">
            <div class="img-box-inner" :style="{ 'background-image': `url(${require('@/assets/img/img-content/startseite/branchenloesung-img.jpg')})` }" style="background-position:center center;">
              <div class="overlay-bg"></div>
              <div class="txt">Mehr entdecken</div>
              <router-link :to="{ name: 'Themenschwerpunkte' }" class="stretched-link"></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-t-m"></div>
    <div class="whitespace-arrow"><span class="material-icons">expand_more</span></div>

    <div class="container" id="vertrauen-verbindet">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-10 padding-tb-s">
          <h2>Vertrauen verbindet.</h2>
          <p>
            Die mehrfach ausgezeichnete journalistische Qualität unserer Inhalte (print und digital) sowie die starke Verbundenheit unserer Leserinnen und Leser zu ihrer „Kleinen“ bieten reichweitenstarke und glaubwürdige Umfelder, von denen Sie als Werbekunde profitieren.
          </p>
          <p>
            An die 10.000 Kunden – national und regional, quer über alle Branchen, vom EPU bis hin zum internationalen Konzern – vertrauen bereits seit vielen Jahren auf die die Kommunikationstärke der Kleinen Zeitung.
          </p>
        </div>
      </div>
    </div>

    <!--<div class="whitespace-line whitespace-bg-blue"></div>-->

    <div class="info-grafik bg-blue-light margin-t-s">
      <div class="container">
        <div class="row justify-content-center ">
          <div class="col-lg-4 col-md-6 border-right-line">
            <div class="txt-box">
              <div class="img-icon img-std"><img src="@/assets/img/generel/Leute-Icon.svg" alt="Leute" /></div>
              <div class="nr ">752.000</div>
              <p>Leserinnen und Leser hat die Kleine Zeitung in der Steiermark und in Kärnten und damit die höchste Reichweite aller Tageszeitungen im Süden Österreichs.</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 border-right-line border-right-line-lg ">
            <div class="txt-box ">
              <div class="img-icon img-std"><img src="@/assets/img/generel/Devices-Icon.svg" alt="Leute" /></div>
              <div class="nr">1,9</div>
              <p>Millionen Userinnen und User erreicht die Kleine Zeitung zusätzlich auf ihren digitalen Kanälen.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="txt-box">
              <div class="img-icon img-uhr"><img src="@/assets/img/generel/Uhr-Icon.svg" alt="Leute" /></div>
              <div class="nr">24/7</div>
              <p>Die Kleine Zeitung erscheint Montag bis Sonntag. 7 Tage die Woche – das ganze Jahr. Mit der Website, der App und den sozialen Kanälen erreicht sie ihre Leser auf allen Kanälen. Zu jeder Zeit.</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8 border-line"></div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6 border-right-line">
            <div class="txt-box">
              <div class="img-icon img-karte"><img src="@/assets/img/generel/Landkarte-Icon.svg" alt="Leute" /></div>
              <div class="nr">18</div>
              <p>Regionalausgaben (10 in der Steiermark und 8 in Kärnten) berichten täglich aus dem unmittelbaren Lebensumfeld der Leserinnen und Leser.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="txt-box border-bottom-none">
              <div class="img-icon img-std"><img src="@/assets/img/generel/Treue-Icon.svg" alt="Leute" /></div>
              <div class="nr">97,5%</div>
              <p>aller Leserinnen und Leser halten ihrer “Kleinen” die Treue und beziehen die Kleine Zeitung im Abo – print und digital.</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center align-self-center">
          <div class="col-sm-6 d-flex align-self-center justify-content-center">
            <div class="btn-std btn-bg-blue-dark margin-t-m">
              <router-link :to="{ name: 'Mediadaten' }">Zu den Mediadaten</router-link>
              <!--<a href="../../pdf/allgemeines/KLZ-Fact-Sheet_Tarif_2021-v2.pdf" target="_blank"></a>-->
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10 text-center padding-t-s">
            <div class="quelle-txt ">
              MA 2020/2021, Erhebungszeitraum: Juli 2020 bis Juni 2021,; ÖAK 1. Halbjahr 2021, abonnierte Auflage (inklusive E-Paper). Wochenschnitt Mo.-Sa.; ÖWA plus 2019
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Startseite',
  components: {},
});
</script>
